export const eggStates = [
  {
    id: '01_start',
    duration: 5000,
    loop: false,
    amountOfFrames: 1,
    chainToState: 1,
  },
  {
    id: '02_loop1',
    duration: 500,
    loop: true,
    amountOfFrames: 11,
  },
  {
    id: '03_scroll',
    duration: 500,
    loop: false,
    amountOfFrames: 16,
  },
  {
    id: '04_end',
    duration: 700,
    loop: false,
    amountOfFrames: 15,
    chainToState: 4,
  },
  {
    id: '05_loop2',
    duration: 600,
    // pauseAfter: 300,
    delay: 3000,
    loop: true,
    amountOfFrames: 13,
    freezeAfter: true,
  },
];

export const eggStatesMobile = [
  {
    id: '01_start',
    duration: 500,
    loop: false,
    amountOfFrames: 1,
    chainToState: 1,
  },
  {
    id: '02_loop1',
    duration: 500,
    loop: false,
    amountOfFrames: 11,
    chainToState: 2,
  },
  {
    id: '03_scroll',
    duration: 500,
    loop: false,
    amountOfFrames: 16,
    chainToState: 3,
  },
  {
    id: '04_end',
    duration: 700,
    loop: false,
    amountOfFrames: 15,
    chainToState: 4,
  },
  {
    id: '05_loop2',
    duration: 600,
    // pauseAfter: 300,
    delay: 3000,
    loop: true,
    amountOfFrames: 13,
    freezeAfter: true,
  },
];

export const turtleStates = [
  {
    id: '01_start',
    duration: 1000,
    loop: false,
    amountOfFrames: 22,
    chainToState: 1,
  },
  {
    id: '02_loop',
    duration: 600,
    loop: true,
    amountOfFrames: 8,
  },
];

export const coffeeStates = [
  {
    id: '00_start',
    duration: 5000,
    loop: false,
    amountOfFrames: 1,
  },
  {
    id: '01_scroll',
    duration: 1000,
    loop: false,
    amountOfFrames: 32,
    chainToState: 2,
  },
  {
    id: '02_loop1',
    duration: 800,
    loop: true,
    amountOfFrames: 21,
  },
];

export const coffeeSliderStates = [
  {
    id: '02_loop1',
    duration: 800,
    loop: true,
    amountOfFrames: 21,
  },
  {
    //1
    id: '03_transition2',
    duration: 700,
    // loop: true,
    amountOfFrames: 15,
    chainToState: 3,
  },
  {
    id: '03_transition2-rev',
    duration: 700,
    // loop: true,
    amountOfFrames: 15,
    chainToState: 0,
  },
  {
    id: '04_loop2',
    duration: 800,
    loop: true,
    amountOfFrames: 18,
  },
  {
    //4
    id: '05_transition3',
    duration: 700,
    // loop: true,
    amountOfFrames: 16,
    chainToState: 6,
  },
  {
    id: '04_transition3-rev',
    duration: 700,
    // loop: true,
    amountOfFrames: 16,
    chainToState: 3,
  },
  {
    id: '06_loop3',
    duration: 800,
    loop: true,
    amountOfFrames: 18,
  },
  {
    //7
    id: '07_transition4',
    duration: 700,
    // loop: true,
    amountOfFrames: 16,
    chainToState: 9,
  },
  {
    id: '06_transition4-rev',
    duration: 700,
    // loop: true,
    amountOfFrames: 16,
    chainToState: 5,
  },
  {
    id: '08_loop4',
    duration: 700,
    loop: true,
    amountOfFrames: 15,
  },
  {
    //10
    id: '09_transition5',
    duration: 700,
    // loop: true,
    amountOfFrames: 16,
  },
  {
    id: '08_transition5-rev',
    duration: 700,
    // loop: true,
    amountOfFrames: 16,
  },
];
