import React, { Component } from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { GridSection, GridBox } from '../Grid';
import {
  sectionHeight,
  stepsLayoutImages,
  stepHeader,
  stepText,
  sliderButton,
} from './layouts';
import { CarouselWrapper } from './ImageCarousel';
import { Heading2, Text } from '../../helper/Typography';
import { CarouselControls } from './CarouselControls';
import { Button } from '../../helper/StyledHelper';
import { Link } from 'gatsby';

export default class StepSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slideIndex: 0,
    };
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextState.slideIndex !== this.state.slideIndex) {
      if (nextState.slideIndex > this.state.slideIndex) {
        switch (nextState.slideIndex) {
          case 2:
            return this.props.setAnimationState('coffeeSlider', 4);
          case 3:
            return this.props.setAnimationState('coffeeSlider', 7);
          case 4:
            return this.props.setAnimationState('coffeeSlider', 10);
          default:
            return this.props.setAnimationState('coffeeSlider', 1);
        }
      } else {
        switch (nextState.slideIndex) {
          case 3:
            return this.props.setAnimationState('coffeeSlider', 11);
          case 2:
            return this.props.setAnimationState('coffeeSlider', 8);
          case 1:
            return this.props.setAnimationState('coffeeSlider', 5);
          default:
            return this.props.setAnimationState('coffeeSlider', 2);
        }
      }
    }
  }

  render() {
    return (
      <CarouselWrapper>
        <CarouselControls
          slides={this.props.slidesText.length}
          prevDisabled={this.state.slideIndex === 0}
          nextDisabled={
            this.state.slideIndex === this.props.slidesText.length - 1
          }
          onNextClick={() =>
            this.setState({
              slideIndex: this.state.slideIndex + 1,
            })
          }
          onPrevClick={() =>
            this.setState({
              slideIndex: this.state.slideIndex - 1,
            })
          }
          active={this.state.slideIndex}
          dark={!this.props.darkTheme}
        />
        <Carousel
          onChange={slideIndex => {
            this.setState({ slideIndex });
          }}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          selectedItem={this.state.slideIndex}
          swipeable
          emulateTouch
          showArrows={false}
          swipeScrollTolerance={3}
        >
          {this.props.slidesText.map((slide, idx) => (
            <div
              key={`slide-${idx}`}
              style={{
                overflow: 'hidden',
                opacity: idx === this.state.slideIndex ? 1 : 0,
                transition: 'all 0.5s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
              }}
            >
              <GridSection gridRows={sectionHeight.stepSlider}>
                <GridBox
                  layout={stepsLayoutImages.horizontal}
                  className={'showAbove-tablet1'}
                >
                  <StepImage
                    stretched
                    urlL={`/assets/pages/about/stepSlider/step${idx + 1}.jpg`}
                  />
                </GridBox>
                <GridBox layout={stepHeader}>
                  <Heading2
                    darkTheme={this.props.darkTheme}
                    style={{ margin: 0 }}
                  >
                    {slide.title}
                  </Heading2>
                </GridBox>
                <GridBox layout={stepText}>
                  {slide.texts.map((text, i) => (
                    <Text.p
                      darkTheme={this.props.darkTheme}
                      key={`sliderText-${idx}-${i}`}
                      idx={i}
                    >
                      {text}
                    </Text.p>
                  ))}
                </GridBox>
                <GridBox layout={sliderButton}>
                  <Link to={this.props.linkTo}>
                    <Button>{this.props.button}</Button>
                  </Link>
                </GridBox>
              </GridSection>
            </div>
          ))}
        </Carousel>
      </CarouselWrapper>
    );
  }
}

const StepImage = styled.div`
  background: url('${({ urlL }) => urlL}');
  background-position: center;
  background-size: cover;
  width: 100%;
  height: ${({ stretched }) => (stretched ? '125%' : '100%')};
  transform: translateX(-160px);


  @media (max-width: 1279px) {
    transform: ${({ stretched }) => stretched && 'translateX(-120px)'};
  }

  @media (max-width: 1024px) {
    transform: ${({ stretched }) => stretched && 'translateX(-240px)'};
  }

  `;
