import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Header, RandomProfile, Image, Teaser } from '../../components/index';
import { GridSection, GridBox, FullWidthElement } from '../../components/Grid';
import StepSlider from '../../components/ImageCarousel/StepSlider';
import {
  eggStates,
  coffeeStates,
  coffeeSliderStates,
  turtleStates,
} from './animations';
import {
  sectionHeight,
  intro,
  patternsLayout,
  startCoffeeLayout,
  coffeeStickyStartLayout,
  textSection,
  textSection2,
  turtleLayout,
  endCoffeeLayout,
  imagesSection,
  team,
  title3,
} from './layouts';
import {
  Heading1,
  Heading2,
  Text,
  TextSectionTitle,
  TextSectionText,
} from '../../helper/Typography';
import Sprite from '../../components/Sprite/Sprite';
import { SimpleImage } from '../../helper/StyledHelper';
import { PageLayout } from '../../components/PageLayout';
import PageEnhancer, { EntryPageProps } from '../../components/PageEnhancer';
import { SeoDataComponent } from '@lws/react-components';
import { getUrlFromId, mapMetaObject } from '../../helper/helperServices';

class AboutUs extends PageEnhancer {
  constructor(props: EntryPageProps) {
    super(props);
    this.state = {
      egg: undefined,
      turtle: undefined,
      coffee: undefined,
      coffeeSlider: undefined,
      darkTheme: false,
      isScreenDesktop: true,
    };
  }

  componentDidMount() {
    this.callSuperComponentDidMountMethods();
    this.setInitialAnimationsState(['egg']);
    window.innerWidth < 1024 && this.setAnimationState('coffeeSlider', 0);
  }

  UNSAFE_componentWillUpdate(
    nextProps: { [x: string]: any },
    nextState: { [x: string]: any }
  ) {
    if (nextState.isScreenDesktop !== this.state.isScreenDesktop) {
      this.setAnimationState(
        nextState.isScreenDesktop ? 'coffeeSlider' : 'coffee',
        undefined
      );
    }
  }

  render() {
    const { generalTexts, routeTexts: about, locale } = this.props.pageContext;
    const { isScreenDesktop } = this.state;

    return (
      <PageLayout
        userLanguage={locale}
        generalText={generalTexts}
        darkTheme={this.state.darkTheme}
      >
        <main className={this.state.darkTheme ? 'App Dark' : 'App Light'}>
          <Header
            darkTheme={this.state.darkTheme}
            lang={locale}
            pathname={this.props.location.pathname}
            generalTexts={generalTexts}
          />
          <SeoDataComponent data={mapMetaObject(about.meta).data} />
          <GridSection gridRows={sectionHeight.intro}>
            <GridBox layout={intro.title} style={{ alignContent: 'start' }}>
              <Text.pGrey style={{ marginTop: '40px' }}>
                {about.intro.label}
              </Text.pGrey>
              <Heading2>{about.intro.title}</Heading2>
            </GridBox>
            {typeof this.state.egg !== 'undefined' && (
              <Sprite
                id={'eggStart'}
                layout={intro.egg}
                name={'egg'}
                startId={'eggStart'}
                endId={'eggEnd'}
                animationState={this.state.egg}
                setAnimationState={this.setAnimationState}
                isSticky={isScreenDesktop}
                isActive={true}
                originalAnimState={1}
                onRelease={() => {
                  this.state.egg !== 3 && this.setAnimationState('egg', 3);
                }}
                onScroll={() =>
                  this.state.egg !== 2 && this.setAnimationState('egg', 2)
                }
                animationsDefinitions={eggStates}
              />
            )}
            <GridBox layout={patternsLayout.p1}>
              <SimpleImage
                src={'/assets/patterns/about_us/stage/pattern-v1-01.svg'}
              />
            </GridBox>
          </GridSection>

          <GridSection gridRows={sectionHeight.image}>
            <FullWidthElement>
              <Image imageFolderUrl={'/assets/pages/about/stage'} />
            </FullWidthElement>
            <GridBox id={'eggEnd'} layout={intro.eggEnd} />
          </GridSection>

          <GridSection gridRows={sectionHeight.textSection}>
            <GridBox layout={textSection.title}>
              <TextSectionTitle style={{ width: '90%' }}>
                {about.textSection.title}
              </TextSectionTitle>
            </GridBox>

            <GridBox layout={textSection.text}>
              {about.textSection.texts.map((text: string, idx: number) => (
                <TextSectionText key={`about-text-${idx}`}>
                  {text}
                </TextSectionText>
              ))}
            </GridBox>
            <VisibilitySensor
              onChange={isVisible => {
                isVisible &&
                  !this.state.turtle &&
                  this.setAnimationState('turtle', 0);
              }}
            >
              <Sprite
                layout={turtleLayout}
                name={'turtle'}
                animationState={this.state.turtle}
                setAnimationState={this.setAnimationState}
                isActive={true}
                isSticky={false}
                animationsDefinitions={turtleStates}
                translateTop={-0.5}
              />
            </VisibilitySensor>
          </GridSection>

          <GridSection gridRows={sectionHeight.imagesSection}>
            <GridBox
              id={'coffeeStickyStart'}
              layout={coffeeStickyStartLayout}
            />

            <GridBox layout={imagesSection.imageLeft}>
              <Image imageFolderUrl={'/assets/pages/about/team1'} />
            </GridBox>
            <GridBox layout={imagesSection.imageRight}>
              <Image imageFolderUrl={'/assets/pages/about/team2'} />
            </GridBox>
            <GridBox layout={patternsLayout.p2}>
              <SimpleImage src={'/assets/patterns/about_us/pattern-01.svg'} />
            </GridBox>
            <GridBox layout={patternsLayout.p3} translateTop={0.5}>
              <SimpleImage src={'/assets/patterns/about_us/pattern-02.svg'} />
            </GridBox>
            <GridBox layout={patternsLayout.p4} className={'showAbove-tablet1'}>
              <SimpleImage src={'/assets/patterns/about_us/pattern-03.svg'} />
            </GridBox>
          </GridSection>

          <GridSection gridRows={sectionHeight.textSection2}>
            <GridBox layout={textSection2.title}>
              <Heading1>{about.textSection2.title}</Heading1>
            </GridBox>
            <GridBox layout={textSection2.texts}>
              {about.textSection2.texts.map((text, idx) => (
                <TextSectionText key={`about-text2-${idx}`}>
                  {text}
                </TextSectionText>
              ))}
            </GridBox>

            {isScreenDesktop && (
              <VisibilitySensor
                className={'showAbove-tablet1'}
                onChange={isVisible => {
                  if (isVisible && !this.state.coffeeSlider) {
                    this.setAnimationState('coffee', 0);
                  }
                }}
              >
                {!this.state.coffeeSlider ? (
                  <Sprite
                    layout={startCoffeeLayout}
                    name={'coffee'}
                    startId={'coffeeStickyStart'}
                    endId={'endCoffee'}
                    animationState={
                      this.state.coffeeSlider ? 'freezed' : this.state.coffee
                    }
                    setAnimationState={this.setAnimationState}
                    isSticky={true}
                    isActive={true}
                    hideWhenFreeze={true}
                    originalAnimState={0}
                    onRelease={() => {
                      requestAnimationFrame(() => {
                        this.setAnimationState('coffeeSlider', 0);
                        this.setAnimationState('coffee', 'freezed');
                      });
                    }}
                    onScroll={() =>
                      this.state.coffee !== 1 &&
                      this.setAnimationState('coffee', 1)
                    }
                    animationsDefinitions={coffeeStates}
                  />
                ) : (
                  <div />
                )}
              </VisibilitySensor>
            )}

            <Sprite
              id={'endCoffee'}
              layout={endCoffeeLayout}
              name={'coffeeSlider'}
              animationState={this.state.coffeeSlider}
              setAnimationState={this.setAnimationState}
              isSticky={false}
              isActive={true}
              translateTop={!isScreenDesktop ? 2.5 : 3}
              animationsDefinitions={coffeeSliderStates}
            />
            <GridBox layout={title3.title}>
              <Heading1>{about.stepSlider.mainTitle}</Heading1>
            </GridBox>
          </GridSection>

          <StepSlider
            slidesText={about.stepSlider.slides}
            animationState={this.state.coffeeSlider}
            setAnimationState={this.setAnimationState}
            isMobile={!isScreenDesktop}
            darkTheme={this.state.darkTheme}
            button={about.stepSlider.button}
            linkTo={getUrlFromId(locale, 'jobs')}
          />

          <GridSection gridRows={sectionHeight.team}>
            <GridBox layout={team.title}>
              <Heading1 darkTheme={this.state.darkTheme}>
                {about.team.title}
              </Heading1>
            </GridBox>
            <GridBox layout={team.text}>
              {about.team.text.map((line: string) => (
                <Text.p darkTheme={this.state.darkTheme}>{line}</Text.p>
              ))}
            </GridBox>
            <GridBox layout={patternsLayout.p5}>
              <SimpleImage src={'/assets/patterns/about_us/pattern-04.svg'} />
            </GridBox>
          </GridSection>
          <VisibilitySensor
            partialVisibility={true}
            onChange={isVisible => {
              this.setState({ darkTheme: isVisible });
            }}
          >
            <RandomProfile
              userLanguage={locale}
              darkTheme={this.state.darkTheme}
            />
          </VisibilitySensor>
        </main>
      </PageLayout>
    );
  }
}
export default AboutUs;
