// BPS:
// 1 - mobile1: 0 - 320
// 2 - mobile2: 321 - 479
// 3 - mobile3: 480 - 767
// 4 - tablet1: 768 - 1023
// 5 - tablet2: 1024 - 1279
// 6 - desktop1: 1280 - 1599
// 7 - desktop2: 1600 - 1919
// 8 - desktop3: 1920 - 2239
// 9 - desktop4: 2240 - x

export const sectionHeight = {
  intro: [5, 5, 5, 5, 5, 4, 4, 4, 6],
  image: [6, 6, 6, 4, 4, 4, 4, 4, 4],
  textSection: [5, 5, 5, 5, 4, 4, 4, 4, 4],
  //todo: missing definitions for this section
  imagesSection: [7, 7, 7, 7, 5, 5, 5, 5, 5],
  textSection2: [11, 11, 9, 11, 10, 8, 8, 9, 7],
  team: [10, 9, 9, 9, 9, 6, 6, 6, 6],
  sliderTitle: [4, 4, 4, 4, 4, 4, 4, 4, 4]
};

export const intro = {
  title: {
    top: [3, 4, 4, 4, 4, 3, 3, 3, 5],
    left: [1, 1, 1, 1, 1, 2, 2, 2, 4],
    boxWidth: [2, 3, 4, 4, 4, 3, 3, 3, 3],
    boxHeight: [2, 2, 2, 1, 1, 1, 1, 1, 1],
  },
  egg: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [2, 2, 2, 2, 2, 2, 2, 2, 3],
    left: [2, 2, 3, 5, 6, 6, 7, 8, 8],
  },
  eggEnd: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [4, 4, 4, 4, 4, 4, 4, 4, 4],
    left: [2, 2, 3, 5, 6, 6, 7, 8, 8],
  },
};

export const title3 = {
  title: {
    top: [10, 9, 8, 9, 8, 7, 7, 7, 7],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 6, 6, 6, 6, 5, 4, 4],
    boxHeight: [1, 2, 2, 2, 2, 2, 2, 1, 1],
  }
};

export const textSection = {
  title: {
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 2, 2, 3, 3, 4],
    boxWidth: [3, 3, 4, 3, 3, 3, 3, 3, 3],
    boxHeight: [1, 1, 1, 1, 1, 1, 1, 1, 1],
  },
  text: {
    top: [3, 3, 3, 3, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 5, 5, 6, 7, 8],
    boxWidth: [3, 3, 4, 4, 4, 3, 3, 3, 3],
    boxHeight: [5, 4, 3, 3, 3, 2, 2, 2, 2],
  },
};

export const turtleLayout = {
  top: [1, 1, 1, 1, 4, 4, 4, 4, 4],
  left: [1, 1, 1, 2, 2, 2, 3, 3, 4],
};

export const imagesSection = {
  imageLeft: {
    top: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    left: [1, 1, 1, 2, 2, 2, 3, 4, 5],
    boxWidth: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    boxHeight: [2, 2, 2, 2, 2, 2, 2, 2, 2],
  },
  imageRight: {
    top: [5, 5, 5, 5, 3, 3, 3, 3, 3],
    left: [2, 2, 2, 3, 5, 5, 6, 7, 8],
    boxWidth: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    boxHeight: [3, 3, 3, 3, 3, 3, 3, 3, 3],
  },
};

export const textSection2 = {
  title: {
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [1, 1, 4, 4, 6, 4, 4, 3, 2],
    boxHeight: [6, 2, 2, 2, 2, 2, 2, 1, 1],
  },
  texts: {
    top: [4, 4, 4, 5, 4, 4, 4, 4, 4],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 4, 4, 4, 4, 4, 4, 4],
    boxHeight: [4, 4, 1, 1, 1, 1, 1, 1, 1],
  },
};

export const team = {
  //bps:        [1, 2, 3, 4, 5, 6, 7, 8, 9]
  title: {
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [1, 1, 2, 2, 2, 2, 2, 2, 2],
    boxHeight: [2, 2, 2, 2, 2, 2, 2, 2, 2],
  },
  text: {
    top: [4, 4, 5, 5, 5, 4, 4, 4, 4],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 4, 4, 4, 4, 4, 4, 4],
    boxHeight: [1, 1, 1, 1, 1, 1, 1, 1, 1],
  },
};

export const startCoffeeLayout = {
  top: [3, 3, 3, 3, 3, 4, 4, 4, 4],
  left: [1, 1, 1, 1, 1, 1, 2, 2, 3],
};

export const coffeeStickyStartLayout = {
  top: [1, 1, 1, 3, 3, 4, 4, 4, 4],
  left: [1, 1, 1, 1, 1, 1, 2, 2, 3],
  boxHeight: [6, 6, 6, 7, 7, 6, 6, 6, 8],
};

export const endCoffeeLayout = {
  top: [9, 8, 7, 9, 6, 4, 4, 5, 4],
  left: [3, 3, 4, 6, 1, 1, 2, 2, 3],
};

export const patternsLayout = {
  //bps:        [1, 2, 3, 4, 5, 6, 7, 8, 9]
  p1: {
    top: [3, 3, 3, 3, 3, 3, 3, 3, 4],
    left: [3, 3, 4, 6, 7, 7, 8, 9, 9],
    boxWidth: [2, 2, 2, 1, 2, 2, 2, 2, 2],
  },
  p2: {
    top: [4, 4, 4, 4, 4, 4, 4, 4, 4],
    left: [1, 1, 1, 1, 1, 1, 2, 3, 4],
  },
  p3: {
    top: [4, 4, 4, 4, 4, 4, 4, 4, 4],
    left: [2, 2, 2, 2, 2, 2, 3, 4, 5],
  },
  p4: {
    top: [4, 4, 4, 4, 4, 4, 4, 4, 4],
    left: [3, 3, 4, 6, 8, 8, 9, 10, 11],
  },
  p5: {
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [3, 3, 4, 6, 7, 7, 8, 9, 10],
  },
};